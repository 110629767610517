.techs__title {
  font-size: 30px;
  text-align: center;
  letter-spacing: -0.04em;
  line-height: 1.22;
  margin: 60px 0 24px;
}

.techs__subtitle {
  font-size: 11px;
  text-align: center;
  letter-spacing: -0.04em;
  margin: 0 0 50px;
  line-height: 1.45;
}

.techs__items {
  list-style-type: none;
  display: grid;
  gap: 10px;
  grid-template-columns: 84px 84px;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.techs__item {
  background-color: #e8e8e8;
  border-radius: 10px;
  min-height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.22;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 768px) {
  .techs__title {
    font-size: 50px;
    margin: 76px 0 22px;
  }

  .techs__subtitle {
    font-size: 12px;
    margin-bottom: 83px;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  .techs__items {
    grid-template-columns: repeat(auto-fit, 84px);
  }
}

@media screen and (min-width: 1280px) {
  .techs {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .techs__title {
    margin: 86px 0 26px;
  }

  .techs__subtitle {
    line-height: 1.42;
    margin-bottom: 100px;
    font-size: 14px;
  }

  .techs__items {
    grid-template-columns: repeat(auto-fit, 90px);
  }

  .techs__item {
    font-size: 14px;
    min-height: 60px;
  }
}
