.not-found {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.not-found__text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.not-found__title {
  text-align: center;
  font-size: 80px;
  margin: 0 0 10px;
  line-height: 1.2;
  font-weight: normal;
}

.not-found__subtitle {
  text-align: center;
  font-size: 12px;
  margin: 0;
}

.not-found__button {
  font-size: 12px;
  color: var(--ultra-blue);
  background: none;
  border: none;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.not-found__button:hover {
  opacity: .7;
  transition: all .3s ease;
  cursor: pointer;
}

@media screen and (min-width: 768px) {

  .not-found__text {
    padding-top: 30px;
  }

  .not-found__title {
    font-size: 140px;
    margin: 0 0 5px;
  }

  .not-found__subtitle {
    text-align: center;
    font-size: 16px;
    margin: 0;
  }

  .not-found__button {
    font-size: 14px;
    position: relative;
    top: 184px;
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1280px) {}
