.header {
  display: flex;
  align-items: center;
  padding: 18px 14px;
  justify-content: space-between;
  position: relative
}

.header_theme_promo {
  background-color: #073042;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .header {
    padding: 18px 30px;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    padding-left: 70px;
    padding-right: 70px;
  }
}
