.like {
  width: 24px;
  cursor: pointer;
  height: 24px;
  flex-shrink: 0;
}

.like:hover {
  filter: drop-shadow(0 0 2px #a3a3a3);
  transition: all .3s ease;
}

.like path {
  fill: #fff
}

.like rect {
  width: 24px;
  height: 24px;
  fill: #F9F9F9;
}

.like_filled path {
  fill: var(--blood-red);
  transition: fill .3s ease;
}

.like:hover path {
  fill: var(--blood-red);
  transition: all .3s ease;
}
